/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import logo from '../data/wk-black.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); // Gerenciar erros de login
  const [loading, setLoading] = useState(false); // Gerenciar estado de carregamento
  const navigate = useNavigate();
  const { login } = useAuth(); // Usa a função de login do AuthContext

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await login(email, password); // Tenta fazer login
      navigate('/dashboard'); // Redireciona para o dashboard se bem-sucedido
    } catch (err) {
      setError('Falha ao entrar. Verifique suas credenciais.');
    }

    setLoading(false);
  };

  return (
    <div className="h-screen w-screen flex flex-col md:flex-row">
      {/* Left side - form */}
      <div className="flex flex-col justify-center w-full md:w-1/2 h-full p-8 bg-white">
        <div className="max-w-sm mx-auto w-full bg-white shadow-lg rounded-lg p-6">
          <div className="items-center gap-3 ml-20 m-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
            <img className="rounded-full h-70 w-40 " src={logo} alt="Logo" />
          </div>
          <form onSubmit={handleSubmit}>
            {error && <p className="text-red-500 mb-4">{error}</p>} {/* Exibe a mensagem de erro */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium mb-2" htmlFor="password">Senha</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>
            <button
              type="submit"
              className={`w-full bg-[#5aae8c] text-white p-2 rounded font-semibold hover:bg-green-600 transition ${loading && 'opacity-50 cursor-not-allowed'}`}
              disabled={loading} // Desabilita o botão enquanto está carregando
            >
              {loading ? 'Entrando...' : 'Entrar'}
            </button>
          </form>
          <div className="mt-4 text-center">
            <p className="text-sm">
              <span
                onClick={() => navigate('/signup')}
                className="text-green-600 hover:underline cursor-pointer"
              >
                Criar conta
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Right side - image */}
      <div className="hidden md:block md:w-1/2 h-full">
        <img
          src="https://images.unsplash.com/photo-1497366754035-f200968a6e72?q=80&w=2301&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Login;
