/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from '@syncfusion/ej2-react-grids';
import { collection, getDocs, query, where, serverTimestamp, updateDoc, doc } from 'firebase/firestore'; // Importa funções do Firestore
import { IconButton, Tooltip, Snackbar, Select, MenuItem, InputLabel, FormControl } from '@mui/material'; // Importa componentes do Material UI
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../contexts/AuthProvider'; // Importa o contexto de autenticação
import { db } from '../firebase'; // Importa a configuração do Firebase
import { Header } from '../components';

const PositionsList = () => {
  const [positions, setPositions] = useState([]); // Estado para armazenar as vagas
  const [alertOpen, setAlertOpen] = useState(false); // Estado para controle do alert
  // const [selectedPositionId, setSelectedPositionId] = useState(null); // Estado para armazenar o ID da vaga a ser removida
  const { currentUser } = useAuth(); // Obtém o usuário atual do contexto de autenticação
  const [pageSize, setPageSize] = useState(25); // Estado para armazenar o número de registros por página
  const [totalRecords, setTotalRecords] = useState(0); // Total de registros da coleção
  console.log(totalRecords);
  useEffect(() => {
    const fetchPositions = async () => {
      if (!currentUser) {
        console.error('Usuário não autenticado');
        return; // Não faz a busca se o usuário não estiver autenticado
      }

      const positionsCollection = collection(db, 'positions');
      const q = query(
        positionsCollection,
        where('removedAt', '==', null),
      );

      try {
        const querySnapshot = await getDocs(q);
        // eslint-disable-next-line no-shadow
        const positionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTotalRecords(positionsData.length); // Atualiza o total de registros
        console.log(positionsData.length);
        setPositions(positionsData); // Atualiza o estado com os dados das vagas
      } catch (error) {
        console.error('Erro ao buscar vagas: ', error);
      }
    };

    fetchPositions();
  }, [currentUser, pageSize]); // Dependência de currentUser e pageSize para refazer a busca ao mudar o estado

  const handleRemove = async (id) => {
    const confirmation = window.confirm('Tem certeza que deseja remover esta vaga?');

    if (confirmation) {
      try {
        // Atualiza a vaga para adicionar removedAt e removedBy
        await updateDoc(doc(db, 'positions', id), {
          removedAt: serverTimestamp(), // Define o timestamp
          removedBy: currentUser.email, // Armazena o email do usuário que removeu
        });
        setPositions(positions.filter((position) => position.id !== id)); // Remove a vaga da lista
        setAlertOpen(true); // Abre o Snackbar para feedback
      } catch (error) {
        console.error('Erro ao remover a vaga: ', error);
      }
    }
  };

  const columns = [
    { field: 'jobTitle', headerText: 'Título', width: '200' },
    { field: 'numPositions', headerText: 'Número de Posições', width: '150' },
    {
      field: 'startDate',
      headerText: 'Data de Início',
      width: '150',
      format: 'dd/MM/yyyy', // Formata a data
    },
    {
      field: 'priority',
      headerText: 'Classificação',
      width: '150',
      template: (data) => (
        <div
          style={{
            backgroundColor: getPriorityColor(data.priority),
            color: 'white',
            padding: '5px',
            borderRadius: '5px',
            textAlign: 'center',
          }}
        >
          {getPriorityLabel(data.priority)}
        </div>
      ),
    },
    { field: 'owner', headerText: 'Dono da Vaga', width: '200' },
    {
      headerText: 'Ações',
      width: '150',
      template: (data) => (
        <div className="flex space-x-2">
          <Tooltip title="Encontrar compatíveis">
            <IconButton onClick={() => window.location.href = '/match-cv'}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar vaga">
            <IconButton onClick={() => window.location.href = `/cadastro-vagas?id=${data.id}`}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remover vaga">
            <IconButton onClick={() => handleRemove(data.id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getPriorityColor = (priority) => {
    switch (priority) {
      case '0':
        return 'red'; // Urgente
      case '1':
        return 'orange'; // Prioritário
      case '2':
        return '#067bf4'; // Importante
      case '3':
        return 'green'; // Radar
      default:
        return 'gray';
    }
  };

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case '0':
        return 'Urgente';
      case '1':
        return 'Prioritário';
      case '2':
        return 'Importante';
      case '3':
        return 'Radar';
      default:
        return '';
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Vagas" title="Vagas cadastradas" />
      <GridComponent
        dataSource={positions}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5, pageSize, pagerTemplate: () => '<div class="pager-template" style="color: #03C9D7;"></div>' }} // Ajuste aqui para usar pageSize definido pelo usuário
        toolbar={['Search']}
      >
        <ColumnsDirective>
          {columns.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Search, Page]} />
      </GridComponent>

      {/* Controle do número de registros por página */}
      <FormControl variant="outlined" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <InputLabel id="page-size-label">Registros por página</InputLabel>
        <Select
          labelId="page-size-label"
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          label="Registros por página"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>

      {/* Snackbar para feedback de remoção */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        message="Vaga removida com sucesso!"
      />
    </div>
  );
};

export default PositionsList;
