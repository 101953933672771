// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBUJ1jmCaDp9xhZlo8i2eLM-S-G3Z95nmc',
  authDomain: 'wkrh-48ec3.firebaseapp.com',
  projectId: 'wkrh-48ec3',
  storageBucket: 'wkrh-48ec3.appspot.com',
  messagingSenderId: '266223783633',
  appId: '1:266223783633:web:e42c58c2f6921b079cbff0',
};

// Inicializando o Firebase
const app = initializeApp(firebaseConfig);

// Inicializando serviços do Firebase
export const auth = getAuth(app);
export const db = getFirestore(app);
