/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import logo from '../data/wk-black.png';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signup } = useAuth(); // Usa a função de signup do AuthContext

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (password !== confirmPassword) {
      return setError('As senhas não coincidem.');
    }

    setLoading(true);

    try {
      await signup(email, password); // Tenta criar conta
      setSuccess('Conta criada com sucesso. Verifique seu e-mail para confirmar sua conta!'); // Mensagem de sucesso

      // Remove a mensagem de sucesso após 2 segundos
      setTimeout(() => {
        setSuccess('');
        navigate('/'); // Redireciona para a página de login após o sucesso
      }, 3000);
    } catch (err) {
      setError('Falha ao criar conta. Tente novamente.');
    }

    setLoading(false);
  };

  return (
    <div className="h-screen w-screen flex flex-col md:flex-row">
      {/* Left side - form */}
      <div className="flex flex-col justify-center w-full md:w-1/2 h-full p-8 bg-white">
        <div className="max-w-sm mx-auto w-full bg-white shadow-lg rounded-lg p-6">
          <div className="items-center gap-3 ml-20 m-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
            <img className="rounded-full h-70 w-40 " src={logo} alt="Logo" />
          </div>
          <form onSubmit={handleSubmit}>
            {error && <p className="text-red-500 mb-4">{error}</p>} {/* Exibe mensagem de erro */}
            {success && <p className="text-green-500 mb-4">{success}</p>} {/* Exibe mensagem de sucesso */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2" htmlFor="password">Senha</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium mb-2" htmlFor="confirmPassword">Confirmar Senha</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>
            <button
              type="submit"
              className={`w-full bg-[#5aae8c] text-white p-2 rounded font-semibold hover:bg-green-600 transition ${loading && 'opacity-50 cursor-not-allowed'}`}
              disabled={loading}
            >
              {loading ? 'Criando conta...' : 'Criar conta'}
            </button>
          </form>
          <div className="mt-4 text-center">
            <p className="text-sm">
              Já tem uma conta?{' '}
              <span
                onClick={() => navigate('/')}
                className="text-green-600 hover:underline cursor-pointer"
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Right side - image */}
      <div className="hidden md:block md:w-1/2 h-full">
        <img
          src="https://images.unsplash.com/photo-1495365200479-c4ed1d35e1aa?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Signup;
