import React, { useEffect } from 'react';
import { AiOutlineMenu, AiFillCaretRight } from 'react-icons/ai';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { useNavigate } from 'react-router-dom'; // Importa o hook useNavigate
import { Cart, Chat, Notification } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { useAuth } from '../contexts/AuthProvider'; // Importa o contexto de autenticação

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();
  const { currentUser } = useAuth(); // Obtém o usuário logado do AuthContext

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);
  const { logout } = useAuth(); // Obtém a função de logout do AuthContext
  const navigate = useNavigate(); // Inicializa o useNavigate

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">

      <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} />
      <div className="flex">
        {/*
        <NavButton title="Cart" customFunc={() => handleClick('cart')} color={currentColor} icon={<FiShoppingCart />} />
        <NavButton title="Chat" dotColor="#03C9D7" customFunc={() => handleClick('chat')} color={currentColor} icon={<BsChatLeft />} />
        <NavButton title="Notification" dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')} color={currentColor} icon={<RiNotification3Line />} />
        */}
        <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick('userProfile')}
          >
            {/*
            <img
              className="rounded-full w-8 h-8"
              src={avatar}
              alt="user-profile"
            />
            */}
            <p>
              {/* Exibe o e-mail do usuário logado */}
              <span className="text-gray-400 font-bold ml-1 text-14">
                {currentUser?.email || 'Usuário'}
              </span>
              <span
                className="ml-8 text-[#03C9D7] text-16 font-bold cursor-pointer hover:text-gray-600" // Mudança de cor
                onClick={handleLogout} // Call handleLogout on click
              >
                SAIR <AiFillCaretRight className="inline" />
              </span>
            </p>
            {/* <MdKeyboardArrowDown className="text-gray-400 text-14" /> */}
          </div>
        </TooltipComponent>

        {isClicked.cart && (<Cart />)}
        {isClicked.chat && (<Chat />)}
        {isClicked.notification && (<Notification />)}
      </div>
    </div>
  );
};

export default Navbar;
