import React from 'react';

import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import ProtectedLayout from './layouts/ProtectedLayout';
import DefaultLayout from './layouts/DefaultLayout';
import { Area, Bar, Calendar, ColorMapping, ColorPicker, Customers, Ecommerce, Editor, Employees, Financial, Kanban, Line, Orders, Pyramid, Stacked } from './pages';
import { Pie } from './components';
import Positions from './pages/Positions';
import PositionsList from './pages/PositionsList';
import Signup from './pages/Signup';

function Router() {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path="/" element={(<Login />)} />
        <Route path="/signup" element={(<Signup />)} />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="/dashboard" element={(<Ecommerce />)} />

        {/* pages  */}
        <Route path="/cadastro-vagas" element={<Positions />} />
        <Route path="/lista-vagas" element={<PositionsList />} />
        <Route path="/#" element={<Orders />} />
        <Route path="/#" element={<Employees />} />
        <Route path="/#" element={<Customers />} />

        {/* apps  */}
        <Route path="/#" element={<Kanban />} />
        <Route path="/#" element={<Editor />} />
        <Route path="/#" element={<Calendar />} />
        <Route path="/#" element={<ColorPicker />} />

        {/* charts  */}
        <Route path="/#" element={<Line />} />
        <Route path="/#" element={<Area />} />
        <Route path="/#" element={<Bar />} />
        <Route path="/#" element={<Pie />} />
        <Route path="/financial" element={<Financial />} />
        <Route path="/color-mapping" element={<ColorMapping />} />
        <Route path="/pyramid" element={<Pyramid />} />
        <Route path="/stacked" element={<Stacked />} />
      </Route>
    </Routes>
  );
}
export default Router;
