/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'; // Importa useEffect e useState
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { TextField } from '@mui/material';
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore'; // Importa funções do Firestore
import { useLocation } from 'react-router-dom'; // Importa useLocation
import { Header } from '../components';
import { db } from '../firebase'; // Importa a configuração do Firebase
import { useAuth } from '../contexts/AuthProvider'; // Importa o contexto de autenticação

const jobSchema = z.object({
  jobTitle: z.string().min(1, 'O título é obrigatório'),
  numPositions: z.number().min(1, 'O número de posições deve ser no mínimo 1'),
  startDate: z.string().min(1, 'A data de início é obrigatória'),
  owner: z.string().min(1, 'O dono da vaga é obrigatório'),
  priority: z.enum(['0', '1', '2', '3']),
  notes: z.string().optional(),
});

const Positions = () => {
  const { currentUser } = useAuth(); // Obtém o usuário atual do contexto de autenticação
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(jobSchema),
  });

  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Obtém a localização atual

  // Função para carregar dados do documento se o id estiver na query
  const loadPositionData = async (id) => {
    const docRef = doc(db, 'positions', id); // Referência do documento
    const docSnap = await getDoc(docRef); // Obtém o documento

    if (docSnap.exists()) {
      const data = docSnap.data();
      reset(data); // Preenche o formulário com os dados do documento
    } else {
      console.error('Nenhum documento encontrado com o ID fornecido');
      alert('Nenhum documento encontrado com o ID fornecido');
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); // Obtém o parâmetro id da query

    if (id) {
      loadPositionData(id); // Carrega os dados do registro correspondente
    }
  }, [location]); // Executa quando a localização muda

  const onSubmit = async (data) => {
    if (!currentUser) {
      console.error('Usuário não autenticado');
      alert('Você precisa estar autenticado para cadastrar uma vaga.');
      return;
    }

    setLoading(true);
    console.log(data);
    try {
      // Adiciona os dados à coleção 'positions' no Firestore
      await addDoc(collection(db, 'positions'), {
        ...data,
        timestamp: serverTimestamp(),
        ownerId: currentUser.uid,
        removedAt: null,
      });
      console.log('Vaga cadastrada com sucesso!');

      setFeedbackMessage('Vaga cadastrada com sucesso!');
      reset();

      setTimeout(() => {
        setFeedbackMessage('');
      }, 3000);
    } catch (error) {
      console.error('Erro ao cadastrar a vaga: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Vagas" title="Cadastro de vagas" />
      {feedbackMessage && (
        <div className="bg-green-100 text-green-800 p-3 rounded-md mb-4">
          {feedbackMessage}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-800" style={{ color: '#333' }}>Título</label>
            <input
              type="text"
              {...register('jobTitle')}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm h-12"
              placeholder="Exemplo: Desenvolvedor front-end"
            />
            {errors.jobTitle && <p className="text-red-600">{errors.jobTitle.message}</p>}
          </div>

          <div>
            <label className="block text-gray-800" style={{ color: '#333' }}>Classificação</label>
            <select
              {...register('priority')}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm h-12"
            >
              <option value="0">Urgente</option>
              <option value="1">Prioritário</option>
              <option value="2">Importante</option>
              <option value="3">Radar</option>
            </select>
            {errors.priority && <p className="text-red-600">{errors.priority.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-800" style={{ color: '#333' }}>Número de Posições</label>
            <input
              type="number"
              {...register('numPositions', { valueAsNumber: true })}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm h-12"
              placeholder="Exemplo: 2"
            />
            {errors.numPositions && <p className="text-red-600">{errors.numPositions.message}</p>}
          </div>

          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DatePicker
                label="Data de Início (WK)"
                renderInput={(params) => <TextField {...params} className="h-12" />}
                onChange={(date) => {
                  if (date) {
                    setValue('startDate', `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`);
                  } else {
                    setValue('startDate', '');
                  }
                }}
              />
            </LocalizationProvider>
            {errors.startDate && <p className="text-red-600">{errors.startDate.message}</p>}
          </div>
        </div>

        <div>
          <label className="block text-gray-800" style={{ color: '#333' }}>Dono da vaga</label>
          <input
            type="text"
            {...register('owner')}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm h-12"
            placeholder="Exemplo: João Silva"
          />
          {errors.owner && <p className="text-red-600">{errors.owner.message}</p>}
        </div>

        <div>
          <label className="block text-gray-800" style={{ color: '#333' }}>Observações</label>
          <textarea
            {...register('notes')}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm"
            rows="4"
            placeholder="Exemplo: Informações adicionais sobre a vaga"
          />
        </div>

        <button
          type="submit"
          className={`text-white px-4 py-2 rounded-md ${loading ? 'bg-yellow-500' : 'bg-green-500'}`}
          disabled={loading}
        >
          {loading ? 'Salvando...' : 'Salvar'}
        </button>
      </form>
    </div>
  );
};

export default Positions;
